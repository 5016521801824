import classNames from 'classnames';
import { cloneElement } from 'react';
import { clamp } from '../../helpers/helpers';
import { ProgressBarCommonProps, ProgressBarProps } from './ProgressBar.types';
import { ProgressBarCircularProps } from './ProgressBarCircular/ProgressBarCircular.types';

enum ProgressValue {
  Min = 0,
  Max = 100,
}

/**
 * Shows the progress of a process.
 * Component ensure ARIA compatibility and clamping of progress value between `0` and `100`.
 *
 * @example
 * <ProgressBar
 *   value={75}
 *   label="Reste 16:55"
 *   linear={<ProgressBarLinear />}
 * />
 */
export default function ProgressBar({
  value,
  linear,
  circular,
  className,
  label,
  shouldForceDarkTheme,
  'data-testid': dataTestId,
}: ProgressBarProps): JSX.Element | null {
  if (typeof value === 'undefined') {
    return null;
  }

  const progressValue = clamp(value, ProgressValue.Min, ProgressValue.Max);

  const commonProps = {
    label,
    shouldForceDarkTheme,
    value: progressValue,
  };

  return (
    <div
      data-testid={dataTestId}
      className={classNames('flex items-center', className)}
      role="progressbar"
      aria-label={`${progressValue}%`}
      aria-valuemin={ProgressValue.Min}
      aria-valuemax={ProgressValue.Max}
      aria-valuenow={progressValue}
    >
      {linear &&
        cloneElement<ProgressBarCommonProps>(linear, { ...commonProps })}
      {circular &&
        cloneElement<ProgressBarCircularProps>(circular, { ...commonProps })}
    </div>
  );
}
