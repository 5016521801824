import { ImageSize } from '@canalplus/mycanal-commons';
import { getCurrentTimestamp } from '../date/date-helper';

export type Ongoing = {
  startTime?: number;
  endTime?: number;
};

/**
 * isOngoing
 *
 * Return boolean when we have both onGoing params and startTime < currentTime < endTime
 *
 * @param {object} [Ongoing] infos about the progress of the media
 * @returns {boolean}
 */
export const isOngoing = (onGoing?: Ongoing): boolean => {
  const currentTime = getCurrentTimestamp();

  return !!(
    onGoing &&
    onGoing.startTime &&
    onGoing.endTime &&
    currentTime >= onGoing.startTime &&
    currentTime <= onGoing.endTime
  );
};

/**
 * getCardLogoChannelSize
 *
 * Helper to get the right size for the channel logo.
 * - Forces size to ExtraLarge for LiveGrid.
 * - Forces size to Large for LiveTv when the image size is Large.
 * - Otherwise, returns Normal.
 * @param imageSize - The size of the image.
 * @param isLiveTvCard - Whether the card is a live tv card.
 * @param isLiveGridCard - Whether the card is a live grid card.
 * @returns
 */
export const getCardLogoChannelSize = ({
  imageSize = 'normal',
  isLiveTvCard = false,
  isLiveGridCard = false,
}: {
  imageSize?: ImageSize;
  isLiveTvCard?: boolean;
  isLiveGridCard?: boolean;
}): ImageSize => {
  if (isLiveGridCard) return 'extraLarge';
  if (isLiveTvCard && imageSize === 'large') {
    return 'large';
  }
  return 'normal';
};
