import { Media } from '@canalplus/dive';
import { ImageSize, Ratio } from '@canalplus/mycanal-commons';
import styles from './ContentRowTemplateItem.css';

export function ContentRowTemplateItemPlaceholder({
  ratio,
  imageSize = 'normal',
}: {
  ratio: Ratio;
  imageSize?: ImageSize;
}): JSX.Element {
  return (
    <li
      className={styles.contentRowTemplateItem}
      data-ratio={`${ratio}${imageSize}`}
    >
      <Media aspectRatio={ratio} data-tv-focusable />
    </li>
  );
}
