import classNames from 'classnames';
import { cloneElement } from 'react';
import { useDiveContext } from '../../../context';
import { ProgressBarLinearCompletedSvg } from '../../../icons/icons';
import ProgressBar from '../ProgressBar';

import {
  progressBarLabelCVA,
  progressBarLinearBackgroundCVA,
  progressBarLinearForegroundCVA,
} from './ProgressBarLinear.cva';
import {
  ProgressBarLinearProps,
  ProgressBarLinearVariant,
} from './ProgressBarLinear.types';

export function ProgressLinear({
  value,
  label,
  variant,
  shouldForceDarkTheme = false,
  showIconOnComplete,
  completedIcon = <ProgressBarLinearCompletedSvg />,
  'data-testid': dataTestId,
}: ProgressBarLinearProps): JSX.Element {
  const { isTv, device } = useDiveContext();

  if (showIconOnComplete) {
    return cloneElement(completedIcon, {
      className: isTv ? 'w-38 h-38' : 'w-20 h-20',
      'aria-hidden': true,
    });
  }

  return (
    <div
      data-testid={dataTestId}
      className={classNames('w-full', {
        'flex items-center justify-center': !!label,
      })}
    >
      <div
        className={progressBarLinearBackgroundCVA({
          device,
          shouldForceDarkTheme,
        })}
        aria-hidden
      >
        <div
          className={progressBarLinearForegroundCVA({
            device,
            variant,
            shouldForceDarkTheme,
          })}
          style={{ width: `${value}%` }}
        />
      </div>
      {!!label && (
        <span
          className={progressBarLabelCVA({
            device,
            variant,
            shouldForceDarkTheme,
          })}
        >
          {label}
        </span>
      )}
    </div>
  );
}

/**
 * A linear version of the `ProgressBar` component. It can display an optional label alongside the progress bar.
 *
 * @example
 * <ProgressBarLinear value={75} label="Remaining time 16:55" />
 */
export function ProgressBarLinear({
  value,
  label,
  variant = ProgressBarLinearVariant.Ongoing,
  className,
  shouldForceDarkTheme,
  showIconOnComplete,
  completedIcon,
  'data-testid': dataTestId,
}: ProgressBarLinearProps): JSX.Element {
  return (
    <ProgressBar
      value={showIconOnComplete ? 100 : value}
      label={label}
      shouldForceDarkTheme={shouldForceDarkTheme}
      linear={
        <ProgressLinear
          variant={variant}
          completedIcon={completedIcon}
          showIconOnComplete={showIconOnComplete}
          data-testid={dataTestId}
        />
      }
      className={classNames(
        className,
        showIconOnComplete ? 'ml-auto right-8 bottom-8 absolute' : 'w-full'
      )}
    />
  );
}
