import { useCallback, useEffect, useRef, useState } from 'react';
import { REFRESH_PROGRESS_BAR_MS } from '../../../constants/limits';
import { getLiveContentProgressPercentage } from '../../../helpers/liveTV/liveTV-helper';

type UseProgressBarValueProps = {
  /**
   * User progress of playing a content in percentage.
   */
  progress?: number;
  /**
   * Start time of a content as a timestamp in milliseconds.
   */
  startTime?: number;
  /**
   * End time of a content as a timestamp in milliseconds.
   */
  endTime?: number;
};

const DEFAULT_PROGRESS = 0;

/**
 * Returns a progress percentage value, based on either one of the following props:
 *
 * - `progress` returned as is.
 * - `startTime` and `endTime` values, for LIVE content
 *
 * Default return value of the hook is `0`.
 *
 * @example
 * // returns 42
 * useProgressBarValue({ progress: 42 })
 *
 * // returns 25 when timestamp is 150_000,
 * // returns 50 when timestamp is 200_000, etc.
 * useProgressBarValue({ startTime: 100_000, endTime: 300_000 })
 */
const useProgressBarValue = ({
  progress: userProgress,
  startTime,
  endTime,
}: UseProgressBarValueProps): number => {
  const [liveContentProgress, setLiveContentProgress] = useState(
    startTime && endTime && getLiveContentProgressPercentage(startTime, endTime)
  );

  const idUpdateInterval = useRef<NodeJS.Timeout>();

  /**
   * Update liveContentProgress based on startTime, endTime, and Date.now()
   */
  const updateLiveContentProgress = useCallback(() => {
    const updatedLiveProgress = getLiveContentProgressPercentage(
      startTime,
      endTime
    );
    if (updatedLiveProgress) {
      setLiveContentProgress(updatedLiveProgress);
    }
  }, [endTime, startTime]);

  /**
   * If content is LIVE, update its progress every minute
   */
  useEffect(() => {
    if (startTime && endTime) {
      // Need to force the default value when the startTime and endTime changed without unmount the component
      setLiveContentProgress(
        getLiveContentProgressPercentage(startTime, endTime)
      );

      idUpdateInterval.current = setInterval(
        updateLiveContentProgress,
        REFRESH_PROGRESS_BAR_MS
      );
    }
    return () => clearInterval(idUpdateInterval.current);
  }, [startTime, endTime, updateLiveContentProgress]);

  /**
   * If LIVE content progress reaches 100, clear update interval
   */
  useEffect(() => {
    if (idUpdateInterval.current && liveContentProgress === 100) {
      clearInterval(idUpdateInterval.current);
    }
  }, [liveContentProgress]);

  return userProgress || liveContentProgress || DEFAULT_PROGRESS;
};

export default useProgressBarValue;
