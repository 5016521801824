import { ProgressBarLinear, ProgressBarLinearProps } from '@canalplus/dive';
import { memo } from 'react';
import { PROGRESS_BAR_COMPLETION_THRESHOLD } from '../../constants/limits';
import useProgressBarValue from './hooks/useProgressBarValue';

export type DiveProgressBarProps = {
  /**
   * User progress of playing a content in percentage.
   */
  progress?: number;
  /**
   * Start time of a content as a timestamp in milliseconds.
   *
   * Compared to `endTime` and `Date.now()` to determine the progress of a live content.
   */
  startTime?: number;
  /**
   * End time of a content as a timestamp in milliseconds.
   *
   * Compared to `startTime` and `Date.now()` to determine the progress of a live content.
   */
  endTime?: number;
  /**
   * Whether the media has been played completely.
   *
   * @default false
   */
  isCompleted?: boolean;
  /**
   * Whether the media is Live.
   *
   * @default - false
   */
  isLive?: boolean;
  /**
   * Whether to show an icon instead of a progress bar, when:
   * - Content is completed (`isCompleted` is truthy) or default progress completion threshold (`90`) is reached
   * - Content is not Live (`isLive` is falsy)
   *
   * @default false
   */
  showIconOnComplete?: ProgressBarLinearProps['shouldForceDarkTheme'];
} & Pick<
  ProgressBarLinearProps,
  'label' | 'completedIcon' | 'shouldForceDarkTheme' | 'className'
>;

// TODO: Remove 'Dive' prefix after all legacy progress bars have been replaced by this component
function DiveProgressBar({
  label,
  progress,
  startTime = 0,
  endTime = 0,
  isCompleted = false,
  shouldForceDarkTheme,
  isLive = false,
  showIconOnComplete = false,
  completedIcon,
  className,
}: DiveProgressBarProps): JSX.Element {
  const progressValue = useProgressBarValue({ progress, startTime, endTime });

  return (
    <ProgressBarLinear
      value={progressValue}
      variant={isLive ? 'live' : 'ongoing'}
      label={label}
      showIconOnComplete={
        showIconOnComplete &&
        !isLive &&
        (isCompleted || progressValue > PROGRESS_BAR_COMPLETION_THRESHOLD)
      }
      completedIcon={completedIcon}
      shouldForceDarkTheme={shouldForceDarkTheme}
      className={className}
    />
  );
}

export default memo(DiveProgressBar);
