import { ProgressBarCommonProps } from '../ProgressBar.types';

/** @public */
export enum ProgressBarLinearVariant {
  Ongoing = 'ongoing',
  Live = 'live',
}

export type ProgressBarLinearProps = ProgressBarCommonProps & {
  /**
   * Linear progress bar styles variants
   *
   * @defaultValue 'ongoing'
   */
  variant?: `${ProgressBarLinearVariant}`;
  /**
   * Icon to show instead of a linear progress bar, when `showIconOnComplete` is set to true.
   *
   * Defaults to DIVE's  `<ProgressBarLinearCompletedSvg />`
   */
  completedIcon?: React.ReactElement;
  /**
   * Whether to show an icon instead of a linear progress bar
   *
   * @default false
   */
  showIconOnComplete?: boolean;
};
