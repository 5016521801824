import { ReactNode } from 'react';
import styles from './HeadlineLiveTv.css';

export function HeadlineLiveTv({
  children,
  title,
  subtitle,
  summary,
}: {
  children: ReactNode;
  title?: string;
  subtitle?: string;
  summary?: string;
}): JSX.Element {
  return (
    <div className={styles.headlineLiveTv}>
      <div className={styles.headlineLiveTv__cardWrapper}>{children}</div>
      <div className={styles.headlineLiveTv__headlineWrapper}>
        <div className={styles.headlineLiveTv__headlineContainer}>
          <h2 className={styles.headlineLiveTv__title}>{title}</h2>
          <h3 className={styles.headlineLiveTv__subtitle}>{subtitle}</h3>
          <p className={styles.headlineLiveTv__summary}>{summary}</p>
        </div>
      </div>
    </div>
  );
}
