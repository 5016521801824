import classNames from 'classnames';
import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { LINKEDIN } from '../../constants/sharing';
import { getShareUrl, getShareWindowOptions } from '../../helpers/sharing';
import styles from './ShareButtonWrapper.module.css';

/**
 * Wraps children with social sharing onClick method
 *
 * @param {string} type            Social Platform indicator
 * @param {string} sharingUrl      Page url
 * @param {string} shareText       Sharing text
 * @param {string} title           Content's title
 * @param {string} shareSite       Site name
 * @param {function} shareContent
 * @param {string} ariaLabel
 */
export class ShareButtonWrapper extends PureComponent {
  openShareWindow = () => {
    const { sharingURL, shareText, title, type, shareContent, shareSite } = this.props;
    const shareUrl = getShareUrl(sharingURL, shareText, title, type, shareSite);
    const shareWindowOptions =
      type === LINKEDIN ? getShareWindowOptions('660', '650') : getShareWindowOptions('560', '510');
    shareContent(type);
    // We use !window in case the user has disabled popups, it will then open in a new window
    !window.open(shareUrl, type, shareWindowOptions); // eslint-disable-line
  };

  render() {
    return (
      <button
        type="button"
        className={classNames(styles.ShareButtonWrapper, this.props.type)}
        onClick={this.openShareWindow}
        title={this.props.title}
        aria-label={this.props.ariaLabel}
      >
        {this.props.children}
      </button>
    );
  }
}

ShareButtonWrapper.propTypes = {
  children: PropTypes.node,
  shareContent: PropTypes.func,
  shareSite: PropTypes.string,
  shareText: PropTypes.string,
  sharingURL: PropTypes.string.isRequired,
  title: PropTypes.string,
  type: PropTypes.string.isRequired,
  ariaLabel: PropTypes.string,
};

ShareButtonWrapper.defaultProps = {
  children: null,
  shareContent: () => {},
  shareSite: '',
  shareText: '',
  title: '',
  ariaLabel: '',
};
