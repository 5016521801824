import {
  ButtonLogo,
  Media,
  MediaFooter,
  MediaOverlay,
  StickerVariant,
} from '@canalplus/dive';
import { DIMENSIONS, ImageSize, Ratio } from '@canalplus/mycanal-commons';
import { CreativeMediaCard } from '@canalplus/mycanal-creativemedia';
import { PersoLists, Template, TitleDisplayMode } from '@canalplus/sdk-hodor';
import { useSelector } from 'react-redux';

import I18n from '../../../../lang';

import { useCallback, useEffect, useMemo, useState } from 'react';
import { REFRESH_PROGRESS_BAR_MS } from '../../../../constants/limits';
import { isOngoing } from '../../../../helpers/card/card-helper';
import { getHodorSticker } from '../../../../helpers/stickers/stickers-helper';
import { ContentStrateV5 } from '../../../../templates/LandingV5/data/formatter';

import {
  featUpcomingHodorStickerSelector,
  isDarkModeSelector,
} from '../../../../store/slices/application-selectors';
import { displayTVModeSelector } from '../../../../store/slices/displayMode-selectors';
import { isImmersiveSelector } from '../../../../store/slices/immersive-selectors';
import { imageQualityPercentageBySettingsSelector } from '../../../../store/slices/user-selectors';

import { CardRemoveButton } from '../../../CardContents/CardRemoveButton/CardRemoveButton';
import Linker from '../../../Linker/Linker';
import type { LinkerData } from '../../../Linker/types';
import DiveProgressBar from '../../../ProgressBar/DiveProgressBar';
import Sticker from '../../../Sticker/Sticker';
import ThumborMediaImage from '../../../ThumborMediaImage/ThumborMediaImage';
import { RankItemStandard } from './RankItemStandard';

import '@canalplus/mycanal-creativemedia/dist/css/CreativeMediaCard/CreativeMediaCard.css';
import classNames from 'classnames/bind';
import styles from '../ContentRowTemplateItem.css';

const cx = classNames.bind(styles);

export type ContentRowTemplateItemStandardProps = {
  content: ContentStrateV5;
  imageSize?: ImageSize;
  isBackgroundTransparent?: boolean;
  isFromDetail?: boolean;
  isFromLanding?: boolean;
  isRemovableItem?: boolean;
  listType?: PersoLists;
  onClickRemove?: (
    event: React.MouseEvent,
    contentID: string,
    listType: PersoLists
  ) => void;
  ratio: Ratio;
  titleDisplayMode?: TitleDisplayMode;
  isTop10?: boolean;
};

export function ContentRowTemplateItemStandard({
  content,
  titleDisplayMode = TitleDisplayMode.All,
  ratio,
  isFromDetail,
  isFromLanding,
  imageSize = 'normal',
  isBackgroundTransparent = false,
  isRemovableItem = false,
  isTop10 = false,
  listType,
  onClickRemove,
}: ContentRowTemplateItemStandardProps): JSX.Element | null {
  const { t } = I18n.useTranslation();

  const isFeatUpcomingHodorSticker = useSelector(
    featUpcomingHodorStickerSelector
  );
  const isFromImmersive = useSelector(isImmersiveSelector) && !isFromLanding;
  const isDark = useSelector(isDarkModeSelector);
  const isTvDevice = useSelector(displayTVModeSelector);
  const qualityUserSettings = useSelector(
    imageQualityPercentageBySettingsSelector
  );

  const hodorSticker = getHodorSticker(
    content.stickers,
    isFeatUpcomingHodorSticker
  );

  const linkerData = useMemo(
    () =>
      ({
        contentID: content?.contentID,
        mainOnClick: content?.onClick,
        context: content?.context,
      }) satisfies LinkerData,
    [content]
  );

  const { startTime, endTime } = content;

  const [isLive, setIsLive] = useState(isOngoing({ startTime, endTime }));

  const updateLiveStatus = useCallback(() => {
    const isOnGoing = isOngoing({ startTime, endTime });
    setIsLive(isOnGoing);
  }, [endTime, startTime]);

  useEffect(() => {
    const updateLiveStatusInterval = setInterval(
      updateLiveStatus,
      REFRESH_PROGRESS_BAR_MS
    );
    return () => clearInterval(updateLiveStatusInterval);
  }, [updateLiveStatus]);

  const isDescriptionVisible =
    titleDisplayMode === TitleDisplayMode.Description ||
    titleDisplayMode === TitleDisplayMode.All;

  const URLImage = content.URLImage;

  const {
    URLImageForDarkMode = URLImage,
    URLImageForLightMode = URLImage,
    imageSpecificities,
  } = content;

  const isTitleVisible = titleDisplayMode !== TitleDisplayMode.None;
  const title = isTitleVisible ? content.title : '';
  const subtitle =
    isTitleVisible && titleDisplayMode !== TitleDisplayMode.TitleOnly
      ? content.subtitle
      : '';
  const description = isDescriptionVisible ? content.description : '';
  const image = {
    default: isDark ? URLImageForDarkMode : URLImageForLightMode,
  };

  const isCreativeMedia = content.type === Template.CreativeMedia;
  const isBottomTitle = !(
    [TitleDisplayMode.LogoTyped, TitleDisplayMode.None] as TitleDisplayMode[]
  ).includes(titleDisplayMode);
  const isClickableCard = !!content.onClick?.URLPage;

  const ariaLabel = isTop10
    ? `Top ${content.rank} ${content.title}`
    : content.title;

  const dimensions = DIMENSIONS[ratio][imageSize];

  const progressBar = content.userProgress ? (
    <DiveProgressBar progress={content.userProgress} showIconOnComplete />
  ) : isLive ? (
    <DiveProgressBar startTime={startTime} endTime={endTime} isLive />
  ) : undefined;

  const getVariantSticker = (): StickerVariant | undefined => {
    if (!content.userProgress && isLive && content.isInOffer) {
      return StickerVariant.Live;
    } else if (content.lastDays) {
      return StickerVariant.LastDays;
    }
    return undefined;
  };

  const mediaCard = (
    <Media
      aspectRatio={ratio}
      aria-label={ariaLabel}
      image={
        <ThumborMediaImage
          dimensions={dimensions}
          url={URLImage}
          alt={content.altImage}
        />
      }
      variant={isBackgroundTransparent ? 'ghost' : 'solid'}
      overlay={
        <MediaOverlay
          actionableElementTop={
            isRemovableItem && onClickRemove && !isTvDevice ? (
              // This component will be remove when it will be refactored dive side
              <CardRemoveButton
                contentID={content.contentID as string}
                listType={listType}
                onClickRemove={onClickRemove}
                hasSticker={Boolean(getVariantSticker() || hodorSticker)}
              />
            ) : undefined
          }
          logo={
            content.URLLogoChannel ? (
              <ThumborMediaImage
                dimensions={
                  isTvDevice
                    ? DIMENSIONS.LOGO_CHANNEL.extraLarge
                    : DIMENSIONS.LOGO_CHANNEL.normal
                }
                url={content.URLLogoChannel}
                alt={content.altLogoChannel}
              />
            ) : undefined
          }
          progressBar={progressBar}
          sticker={
            <Sticker
              sticker={hodorSticker}
              startTime={startTime}
              endTime={endTime}
              variant={getVariantSticker()}
            />
          }
        />
      }
      footer={
        isBottomTitle ? (
          <MediaFooter
            title={title}
            subtitle={subtitle}
            description={description}
          />
        ) : undefined
      }
      data-tv-focusable={!isClickableCard}
    />
  );

  // Clickable card
  if (isClickableCard) {
    const itemClassnames = cx('contentRowTemplateItem', {
      'contentRowTemplateItem--carousel':
        ratio === Ratio.Ratio166 && !isDescriptionVisible,
      'contentRowTemplateItem--immersive': isFromImmersive,
      'contentRowTemplateItem--logoTyped':
        titleDisplayMode === TitleDisplayMode.LogoTyped,
      'contentRowTemplateItem--logoTyped--top10':
        titleDisplayMode === TitleDisplayMode.LogoTyped && isTop10,
      'contentRowTemplateItem--detailV5': isFromDetail,
      'contentRowTemplateItem--top10': isTop10,
      'contentRowTemplateItem--creativeMedia': isCreativeMedia,
      'contentRowTemplateItem--creativeMediaInDetailV5':
        isCreativeMedia && isFromDetail,
    });

    return (
      <li className={itemClassnames} data-ratio={`${ratio}${imageSize}`}>
        <Linker
          className={cx('contentRowTemplateItem__itemLink', {
            'contentRowTemplateItem__itemLink--top10': isTop10,
          })}
          data={linkerData}
          title={ariaLabel}
          ariaLabel={ariaLabel}
        >
          {isTop10 && !!content.rank && (
            <RankItemStandard rank={content.rank} />
          )}
          {imageSpecificities === 'isLogo' ? (
            <ButtonLogo
              as="div"
              logo={
                <ThumborMediaImage
                  url={image.default}
                  dimensions={dimensions}
                  alt={content.altImage}
                  height="auto"
                />
              }
            />
          ) : (
            <div
              className={cx('contentRowTemplateItem__itemLink', {
                'contentRowTemplateItem__itemLink--top10__media': isTop10,
              })}
            >
              {isCreativeMedia && image.default ? (
                <CreativeMediaCard
                  title={title}
                  image={image.default}
                  qualityUserSettings={qualityUserSettings}
                  t={t}
                  isImmersive={false}
                  description={description}
                />
              ) : (
                mediaCard
              )}
            </div>
          )}
        </Linker>
      </li>
    );
  }

  // Not clickable card
  return (
    <li
      className={styles.contentRowTemplateItem}
      data-ratio={`${ratio}${imageSize}`}
    >
      {imageSpecificities === 'isLogo' ? (
        <ButtonLogo
          as="button"
          logo={
            <ThumborMediaImage
              url={image.default}
              dimensions={dimensions}
              alt={content.altImage}
              height="auto"
            />
          }
        />
      ) : (
        mediaCard
      )}
    </li>
  );
}
